import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import useIdleTimeout from '../../hooks/useIdleTimeout'
import AuthContext from "../../contexts/AuthContext";
import { bConst } from '../../constants';

import {
  // Box as MuiBox,
  // Chip,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/bloqs_white.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

// const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 50px;
  cursor: pointer;
  flex-grow: 0;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const LogoSidebar = styled(Logo)`
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  height: 60px;

  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
`;


const Sidebar = ({ items, closeSideBar, showFooter = true, ...rest }) => {
  const { logout2 } = React.useContext(AuthContext);
  
  const handleIdle = () => {
    if (idleTimer.isIdle!==true) { logout2() };
  }
  const idleTimer = useIdleTimeout({ onIdle: handleIdle, idleTime: bConst.IdleTimeOut })

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/">
        <LogoSidebar />{" "}
      </Brand>
      <SidebarNav items={items} closeSideBar={closeSideBar} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
