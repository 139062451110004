
import {
  Layers,
  Clipboard,
  User,
  Users,
  Edit,
  BookOpen,
  Calendar,
  Briefcase,
  Heart,
  Printer,
} from "react-feather";
import {IsBevoegd} from "../auth/RequireRecht";
import {bConst} from "../../constants";


// 
const clientSection = [
  {
    href: "/clients",
    icon: Users,
    title: "Clientenoverzicht",
  },
];

const clientPortaalSection = [
  {
    href: "/portaal_afspraken",
    icon: Calendar,
    title: "Afspraken",
  },

  {
    href: "/portaal_taken",
    icon: Clipboard,
    title: "Taken",
  },
    
  {
    href: "/portaal_documenten",
    icon: BookOpen,
    title: "Documenten",
  }, 
];

const cp_AfsprakenSection = [
  {
    href: "/portaal_afspraken",
    icon: Calendar,
    title: "Afspraken",
  },
];

const cp_TakenSection = [
  {
    href: "/portaal_taken",
    icon: Clipboard,
    title: "Taken",
  },
];

const cp_DocSection = [  
  {
    href: "/portaal_documenten",
    icon: BookOpen,
    title: "Documenten",
  }, 

];

let portaalItems = []

const userSection1 = [
  {
    href: "/logout",
    icon: User,
    title: "Afmelden",
  },  
];

const userSection2 = [
  {
    href: "/wijzig_password",
    icon: Edit,
    title: "Wachtwoord",
  },

  {
    href: "/logout",
    icon: User,
    title: "Afmelden",
  },  
];

// const dashboardSection = [
//   {
//     href: "/dashboard",
//     icon: Layers,
//     title: "Dashboard",
//   },
//   {
//     href: "/profile",
//     icon: User,
//     title: "Profiel",
//   },
// ];

// const dienstenSection = [
  // {
  //   href: "",
  //   icon: Heart,
  //   title: "Zorg",
  // },
  // {
  //   href: "",
  //   icon: Briefcase,
  //   title: "Werk",
  // },
// ];

// const managementSection = [
//   {
//     href: "/orders",
//     icon: Printer,
//     title: "Rapportages",
//   },
//   {
//     href: "/orders",
//     icon: Users,
//     title: "Personeel",
//   },
//   {
//     href: "/orders",
//     icon: Clipboard,
//     title: "Prikbord",
//   },
// ];

const testSection = [
  {
    href: "/serverReadTest",
    icon: Clipboard,
    title: "Server Readtest",
  },
  {
    href: "/serverWriteTest",
    icon: Clipboard,
    title: "Server Writetest",
  },  
  // {
  //   href: "/login",
  //   icon: User,
  //   title: "Login",
  // },
  // {
  //   // href: "/logout",
  //   icon: User,
  //   title: "Logout",
  // },
  {
    href: "/testclients",
    icon: Clipboard,
    title: "Test Clients",
  },  
  
  {
    href: "/testpage",
    icon: Clipboard,
    title: "Test Page",
  },
];

// const navItemDienst = {
//     title: "Diensten",
//     pages: dienstenSection,
//   };

// const navItemMngt = {
//     title: "Management",
//     pages: managementSection,
//   };

// const navItemAlg = 
//   {
//     title: "Algemeen",
//     pages: dashboardSection,
//   };

  
// const navItemTest = {
//     title: "Test sectie",
//     pages: testSection,
//   };

const navItemClients =
  {
    title: "Clienten",
    pages: clientSection,
  };

  const navItemClientPortaal =
  {
    title: "Clientportaal",
    pages: [], 
  };
  
  const navItemUser1 = 
  {
    title: "Gebruiker",
    pages: userSection1,
  };
  
  const navItemUser2 = 
  {
    title: "Gebruiker",
    pages: userSection2,
  };
  
  
export let navItems = [];

export function configerSidebar (isMedw, cp_Doc, cp_Afspraken, cp_Taken) {
  navItems = []
  portaalItems = []
  if (isMedw===1) {
    // navItems.push(navItemAlg)
    navItems.push(navItemClients) 
    navItems.push(navItemUser1)   
    // if (IsBevoegd(bConst.r_MngtMenu)) { navItems.push(navItemMngt) }
    // if (process.env.NODE_ENV === 'development') { navItems.push(navItemTest) }

  } else {
    if (cp_Afspraken!==0) { portaalItems.push(cp_AfsprakenSection[0])}  // Add afspraken 
    if (cp_Taken!==0) { portaalItems.push(cp_TakenSection[0])}          // Add Taken
    if (cp_Doc!==0) { portaalItems.push(cp_DocSection[0])}              // Add Doc
    navItemClientPortaal.pages=portaalItems
     
    navItems.push(navItemClientPortaal) 
    navItems.push(navItemUser2)   
  }
  
}

