import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import AuthContext from "../../contexts/AuthContext";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";

import {
  Alert as MuiAlert,
  Typography,
  Button,
  TextField as MuiTextField,
} from "@mui/material";

import {
  SaveAltOutlined,
  Close, 
} from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

export function FillNewPW(props) {
  const withPW = props.withPW
  
  const [PW, setPW] = React.useState("");
  const [newPW1, setNewPW1] = React.useState("");
  const [newPW2, setNewPW2] = React.useState("");

  const { checkPW, savePW, setErrorMsg, errorMsg, logout } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const fillinPW = React.useCallback( async () => {
    if ( (!withPW) || (withPW && (await checkPW(PW))) ) {
      if ( newPW1===newPW2 ) {
        if ( newPW1.length>5 && isPWStrong(newPW1) ) {
          if (await savePW(newPW1)) { 
            alert('Het nieuwe wachtwoord is succelvol opgeslagen.')
          } else {
            setErrorMsg('Er is een fout opgetreden bij het opslaan van het nieuwe wachtwoord! Probeer het opnieuw.')          
          }
        } else {
          setErrorMsg('Een wachtwoord moet minimaal 6 tekens lang zijn en bestaan uit een of meer kleine letter(s), hoofdletter(s), cijfer(s) en speciale character(s)!')
        }
      } else {
        setErrorMsg('De beide wachtwoorden zijn niet gelijk!')
      }
    } else {
      setErrorMsg('Het huidige wachtwoorden is niet correct!')
    }  
    setPW('')
    setNewPW1('')
    setNewPW2('')
  })   
  
  function isPWStrong(PW) {
    let strong=false
    let hl=false
    let kl=false
    let sc=false
    let cf=false

    for (let i=0; i<PW.length; i++) {
      if (PW.charCodeAt(i)>='A'.charCodeAt(0) && PW.charCodeAt(i)<='Z'.charCodeAt(0)) { hl=true }
      if (PW.charCodeAt(i)>='a'.charCodeAt(0) && PW.charCodeAt(i)<='z'.charCodeAt(0)) { kl=true }
      if (PW.charCodeAt(i)>='0'.charCodeAt(0) && PW.charCodeAt(i)<='9'.charCodeAt(0)) { cf=true }
      if (PW.charCodeAt(i)>='!'.charCodeAt(0) && PW.charCodeAt(i)<='/'.charCodeAt(0)) { sc=true }
      if (PW.charCodeAt(i)>=':'.charCodeAt(0) && PW.charCodeAt(i)<='@'.charCodeAt(0)) { sc=true }
      if (PW.charCodeAt(i)>='['.charCodeAt(0) && PW.charCodeAt(i)<='`'.charCodeAt(0)) { sc=true }
      if (PW.charCodeAt(i)>='{'.charCodeAt(0) && PW.charCodeAt(i)<='~'.charCodeAt(0)) { sc=true }

    }
    if (hl && kl && sc && cf) { strong=true }
    return strong 
  } 
  
  const handleCancel= () => {
    setErrorMsg("")
    if (props.handleClose) { props.handleClose() }
    if (withPW) {} else {logout()}
    // navigate("/logout")
  }  
  
  return (
    // <form noValidate >
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Vul een nieuw wachtwoord in....
      </Typography>

      { errorMsg && 
        <Alert mt={2} mb={3} severity="warning">
          {errorMsg}
        </Alert>
      }
    { withPW     
      ?  <TextField
          type="password"
          name="password"
          label="Huidige wachtwoord"
          autoFocus="true"
          fullWidth
          value={PW}
          onChange={(e) => setPW(e.target.value)}
          my={1}
        />
      : null
    }

    <TextField
        type="password"
        name="password"
        label="Nieuw wachtwoord"
        fullWidth
        value={newPW1}
        onChange={(e) => setNewPW1(e.target.value)}
        my={2}
      />

    <TextField
        type="password"
        name="password"
        label="Herhaal het nieuwe wachtwoord"
        fullWidth
        value={newPW2}
        onChange={(e) => setNewPW2(e.target.value)}
        my={2}
      />

      <Stack space={4} mt={4} direction="row" justifyContent="space-evenly" alignItems="flex-end">
      <Button
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<SaveAltOutlined />}
        onClick={fillinPW}>
        Opslaan
      </Button> 

      <Button
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<Close />}
        onClick={handleCancel} 
      >
        Annuleren
      </Button> 

      </Stack>
    </React.Fragment>  
    //  </form> 
    )
  }

export default FillNewPW;
