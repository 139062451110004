import * as React from "react";
import styled, { withTheme } from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Hidden,
  Tooltip,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  // Typography as MuiTypography,
  Toolbar,
  Typography,
} from "@mui/material";

import { Menu as MenuIcon, SentimentSatisfiedAlt as Smile } from "@mui/icons-material";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarMessagesDropdown from "./NavbarMessagesDropdown";

import NavbarUserDropdown from "./NavbarUserDropdown";
import AuthContext from "../../contexts/AuthContext";
import { Users } from "react-feather";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ openSideBar }) => {
  const navigate = useNavigate();
  const { user } = React.useContext(AuthContext);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open menu"
                  onClick={openSideBar}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Typography variant="subtitle1">
            <div style={{
              display: 'flex',
              alignItems: 'center'
              }}>
              { user.isMedw===1 
              ? 'WebApp: '+(user && user.bdrNaam+'')
              : 'Cliëntportaal: '+(user && user.bdrNaam+'')
              }
              <br/>
              Welkom, {(user && user.displayName+'') || "guest"}
            </div>  
            </Typography>
              <Grid item xs/>
                { user.isMedw===1
                ? <Grid item >
                    <Tooltip title="Cliënten">
                      <IconButton color="inherit" onClick={() => navigate("/clients")} >
                        <Users /> 
                      </IconButton>
                    </Tooltip>
                    <NavbarUserDropdown />
                  </Grid>  
                : null 
                }
              </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
