import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import AuthContext from "../../contexts/AuthContext";
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import TfaQRform from "./TfaQRform"; 

import {
  Grid,
  Alert as MuiAlert,
  Typography,
  Button,
  TextField as MuiTextField,
} from "@mui/material";

import {
  Check,
  Close,
  QrCode2Outlined,
} from "@mui/icons-material";


const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

export function FillinQRCode() {
  const { logout, verifyTfa, errorMsg } = React.useContext(AuthContext);
 
  const [isSubmitting, setSubmitting ] = React.useState(false);
  const [tfaToken, setTfaToken] = React.useState("");
   
  const logoutUser = async (event) => {
    setSubmitting(true);
    await logout();
    setSubmitting(false);
  }  

  const verifyCode = async (event) => {
    event.preventDefault();
    // console.log('Token: '+tfaToken)
    setSubmitting(true);
    await verifyTfa(tfaToken)
    setTfaToken("")
    setSubmitting(false);
  } 
 
  return (
    <form noValidate onSubmit={verifyCode}>

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Code van de Google-Authenticator-App ....
      </Typography>

      { errorMsg && 
        <Alert mt={2} mb={3} severity="warning">
          {errorMsg}
        </Alert>
      }

      <Grid container mt={4} mb={4} spacing={5}>
        <Grid item xs={2}>
        </Grid>
        
        <Grid item xs={8} justifyContent="center" alignItems="center">
          <TextField
            type="text"
            label="Authenticator-code"
            autoFocus
            cus
            value={tfaToken}
            required
            sx={{ width: '180px' }}
            onChange={(e) => setTfaToken(e.target.value)}
            my={1}
          />
        </Grid>

        {/* <Grid item  xs={2} justifyContent="center">
          <IconButton size="large" color="primary" onClick={scanQrCode}> 
            <QrCode2Outlined fontSize="large" /> 
          </IconButton>
        </Grid> */}
      </Grid>

      <Grid container mt={4} mb={4} spacing={5}>
        <Grid item xs={2}>
        </Grid>

        <Grid item xs={6}>
          <Button                  
            type="button"                  
            variant="contained"
            color="primary"
            disabled={isSubmitting} 
            sx={{ width: '180px' }}
            startIcon={<Check />}
            onClick={verifyCode} 
          > 
            Controleer de code
          </Button> 
        </Grid>

        <Grid item xs={2}>
          <Button 
            type="button"                  
            variant="contained"
            startIcon={<Close />}
            onClick={logoutUser} 
          > 
            Annuleren
          </Button> 
        </Grid>
      </Grid>
      
    </form> 
  )
}

export default FillinQRCode;
