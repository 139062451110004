import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { bConst, bMess } from "../../constants";
import { spacing } from "@mui/system";
import AuthContext from "../../contexts/AuthContext";
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import * as speakeasy from 'speakeasy'; 
import * as qrcode from 'qrcode';
import { copyToClipboard } from "../../utils/ComProc";

import {
  Alert as MuiAlert,
  Typography,
  Grid,
  Button,
  TextField as MuiTextField,
} from "@mui/material";

import {
  FilterNoneOutlined, 
  Check,
  Close,
} from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

export function ScanQR() {
  const { logout, saveTfa, setScanQR, QR, user} = React.useContext(AuthContext);

  const [tfaSecret, setTfaSecret] = React.useState(undefined);
  const [qrCodeUri, setQrCodeUri] = React.useState(undefined);
 
  const logoutUser = async () => {
    await logout();
  }   
 
  const onCloseQR = async () => {
    setScanQR(false)
    if (!QR) { logoutUser() }
  } 

  let accountName='bloqs';
  if (user.isMedw===1) { accountName='bloqs Cliëntvolgsysteem' } else { accountName='bloqs Cliëntportaal' } 
  
  const generateSecret = async() => {
     return speakeasy.generateSecret({ length: 20 });
  }

  const onGenTfa = async(e) => {
    const newSecret = await generateSecret();
    setTfaSecret(newSecret);
    console.log('Secret:  '+newSecret.base32)
    const qrCodeUrl = `otpauth://totp/${accountName}?secret=${newSecret.base32}`;
    const newQrCodeUri = await qrcode.toDataURL(qrCodeUrl);
    setQrCodeUri(newQrCodeUri);
  };

  const genTfa = async() => {
    const newSecret =  await generateSecret();
    setTfaSecret(newSecret);
    // console.log('Secret:  '+newSecret.base32)
    const qrCodeUrl = `otpauth://totp/${accountName}?secret=${newSecret.base32}`;
    const newQrCodeUri = await qrcode.toDataURL(qrCodeUrl);
    setQrCodeUri(newQrCodeUri);
  };

  const save_tfa = async() => {
    const saved =  await saveTfa(tfaSecret.base32)
     if (saved) {setScanQR(false)} else { logoutUser() }
  }
  
  const save_code = async() => {
    copyToClipboard(tfaSecret.base32)
  }

  const genQR = () => {
   if (!tfaSecret) { genTfa() }
  }

  return (
    <React.Fragment>
      { genQR() }
 
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        QR-code voor de Google-Authenticator-App
      </Typography>
      <Typography component="h4" variant="h8" align="center" gutterBottom>
        QR-code scannen met de App en opslaan
      </Typography>

      <Grid container mt={4} mb={6} spacing={1} justifyContent="center" alignItems="center">
        {qrCodeUri && <img src={qrCodeUri}  alt="QR code for Google Authenticator" /> }

        {/* <IconButton size="large" onClick={save_code} > <FilterNoneOutlined fontSize="large" /> </IconButton> */}
      </Grid>

      <Grid container spacing={1} justifyContent="center">
        {/* <Grid item xs={3}>
        </Grid> */}

        <Grid container xs={4}  spacing={1} justifyContent="center">
            <Button type="button" variant="contained" disabled={!tfaSecret} startIcon={<FilterNoneOutlined />} onClick={save_code}> 
              Kopiëren
            </Button> 
        </Grid>

        <Grid container xs={4}  spacing={1} justifyContent="center">
            <Button type="button" variant="contained" disabled={!tfaSecret} startIcon={<Check />} onClick={save_tfa}> 
              Opslaan
            </Button> 
        </Grid>

        <Grid container xs={4} spacing={1} justifyContent="center">
            <Button type="button" variant="contained" startIcon={<Close />} onClick={onCloseQR} > 
              Annuleren
            </Button> 
        </Grid>
       </Grid>

    </React.Fragment>      
  )
}

export default ScanQR;
