import React, { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import async from "./components/Async";
import AuthContext from "./contexts/AuthContext";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import LoginLayout from "./layouts/LoginLayout";
import MainLayout from "./layouts/MainLayout";

// Auth components
import {Login} from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";
import Page404 from "./pages/Page404/Page404";
import Page500 from "./pages/Page500/Page500";
const ChangePW = async(() => import("./pages/Login/ChangePW"));

// Pages webApp
const Clients = async(() => import("./pages/Clients/Clients"));
const Clientdossier = async(() => import("./pages/Clientdossier/Clientdossier"));

// Pages Portaal
const Cp_Documenten= async(() => import("./pages/ClientenPortaal/Cp_Documenten"));
const Cp_Afspraken= async(() => import("./pages/ClientenPortaal/Cp_Afspraken"));
const Cp_Taken= async(() => import("./pages/ClientenPortaal/Cp_Taken"));

// Pages Diverse
const Dashboard = async(() => import("./pages/Dashboard/Dashboard"));
const Profile = async(() => import("./pages/Profile/Profile"));
const Testclients = async(() => import("./pages/Testclients/Testclients"));
const Testpage = async(() => import("./pages/Testpage/Testpage"));
const Serverreadtest = async(() => import("./pages/serverReadTest/serverReadTest"));
const Serverwritetest = async(() => import("./pages/serverWriteTest/serverWriteTest"));

function isMedw(user) {
  return user && user.isMedw===1
  // return false
}

function AppRoutes() {
  const { user } = useContext(AuthContext);
  let routes = []

  if ( isMedw(user) ) {
    routes =[                     // medewerkers routes     
      {
        path: "/",
        element: (
          <MainLayout />
        ),
          children: [
          {
            path: "",
            element: <Clients />,
          },
        ],
      },
  
      {
        path: "clients",
        element:(
          <MainLayout />
        ),
        children: [
          {
            path: "",
            element: <Clients />,
          },
        ],
      },
  
      {
        path: "clientdossier",
        element:(
          <MainLayout />
        ),
        children: [
          {
            path: "",
            element: <Clientdossier />,
          },
        ],
      },
  
      {
        path: "logout",
        element: <LoginLayout />,
        children: [
          {
            path: "",
            element: <Logout />,
          },
        ],
      },
  
      // {
      //   path: "login",
      //   element: <LoginLayout />,
      //   children: [
      //     {
      //       path: "",
      //       element: <Login />,
      //     },
      //   ],
      // },
  
      // {
      //   path: "dashboard",
      //   element: (
      //     <MainLayout />
      //   ),
      //     children: [
      //     {
      //       path: "",
      //       element: <Dashboard />,
      //     },
      //   ],
      // },
  
      // {
      //   path: "profile",
      //   element:(
      //     <MainLayout />
      //   ),
      //     children: [
      //     {
      //       path: "",
      //       element: <Profile />,
      //     },
      //   ],
      // },
  
  
      // {
      //   path: "testclients",
      //   element:(
      //     <MainLayout />
      //   ),
      //   children: [
      //     {
      //       path: "",
      //       element: <Testclients />,
      //     },
      //   ],
      // },
  
      // {
      //   path: "testpage",
      //   element:(
      //       <MainLayout />
      //   ),
      //   children: [
      //     {
      //       path: "",
      //       element: <Testpage />,
      //     },
      //   ],
      // },
  
      // {
      //   path: "serverReadTest",
      //   element:(
      //     <MainLayout />
      //   ),
      //   children: [
      //     {
      //       path: "",
      //       element: <Serverreadtest />,
      //     },
      //   ],
      // },
  
      // {
      //   path: "serverWriteTest",
      //   element:(
      //     <MainLayout />
      //   ),
      //   children: [
      //     {
      //       path: "",
      //       element: <Serverwritetest />,
      //     },
      //   ],
      // },
  
      {  
        path: "*",
        element: <LoginLayout />,
        children: [
          {
            path: "*",
            element: <Page404 />,
          },
        ],
      },
    ]
  } else { 
    routes = [                           // Clienten protaal routes        
      // {
      //   path: "/",
      //   element: (
      //     <MainLayout />
      //   ),
      //     children: [
      //     {
      //       path: "",
      //       element: <Cp_Afspraken />,
      //     },
      //   ],
      // },

      {
        path: "/portaal_afspraken",
        element: (
          <MainLayout />
        ),
          children: [
          {
            path: "",
            element: <Cp_Afspraken />,
          },
        ],
      },

      {
        path: "/portaal_documenten",
        element: (
          <MainLayout />
        ),
          children: [
          {
            path: "",
            element: <Cp_Documenten />,
          },
        ],
      },

      {
        path: "/portaal_taken",
        element: (
          <MainLayout />
        ),
          children: [
          {
            path: "",
            element: <Cp_Taken />, 
          },
        ],
      },

      {
        path: "login",
        element: <LoginLayout />,
        children: [
          {
            path: "",
            element: <Login />,
          },
        ],
      },

      {
        path: "logout",
        element: <LoginLayout />,
        children: [
          {
            path: "",
            element: <Logout />,
          },
        ],
      },

      {
        path: "wijzig_password",
        element:<MainLayout />,
        children: [
          {
            path: "",
            element: <ChangePW withPW={true}  />,
          },
        ],
      },

      {  
        path: "*",
        element: <LoginLayout />,
        children: [
          {
            path: "*",
            element: <Page404 />,
          },
        ],
      },
    ]

    if (user.cp_Afspraken!==0) {   // set Default route clientportaal
      routes.push(                                
        {
          path: "/",
          element: (
            <MainLayout />
          ),
            children: [
            {
              path: "",
              element: <Cp_Afspraken />,
            },
          ],
        },
      )
    } else {
      if (user.cp_Taken!==0) {
        routes.push(                                  
          {
            path: "/",
            element: (
              <MainLayout />
            ),
              children: [
              {
                path: "",
                element: <Cp_Taken />,
              },
            ],
          },
        )
      } else {
        if (user.cp_Doc!==0) {
          routes.push(                                 
            {
              path: "/",
              element: (
                <MainLayout />
              ),
                children: [
                {
                  path: "",
                  element: <Cp_Documenten />,
                },
              ],
            },
          )
        } else {
          routes.push(                                 
            {
              path: "/",
              element: (
                <LoginLayout />
              ),
                children: [
                {
                  path: "",
                  element: <Logout />,
                },
              ],
            },
          )          
        }
      }  
    }  
  }   
  return useRoutes(routes)
}

export default AppRoutes;
